<template>
  <div class="w-screen h-full bg-black md:hidden">
    <transition name="fade">
      <div v-show="noticePopup" class="notice_popup overflow-y-auto h-screen w-screen fixed flex items-center bg-black bg-opacity-90 z-20">
        <div class="m-auto">
          <div class="heading flex justify-center items-center flex-wrap">
            <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold">
              兌換結果
            </h3>
          </div>
          <hr class="w-1/5 text-white mx-auto mt-8 border-2">
          <div class="form mx-4 mt-10 before" autocomplete="off">
            <p class="text-white text-lg tracking-widest text-center">
              {{ msg }}
            </p>
            <div class="flex justify-center mt-10 pb-10" >
              <button 
                class="mx-auto bg-white text-buyBackground border-black border-2 shadow-inputActive rounded-lg text-lg px-5 py-2" 
                @click="noticePopup = false"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="header fixed top-0 w-full z-10" ref="header">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        />
      </div>
    </div>
    <div class="fake-header opacity-0 top-0 w-full">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        />
      </div>
    </div>

    <div class="event_result bg-buyBackground flex" :style="fullHeight">
      <div class="m-auto">
        <div class="heading flex justify-center items-center flex-wrap">
          <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold">
            {{ storeName }}
          </h3>
        </div>
        <hr class="w-1/5 text-white mx-auto mt-8 border-2">
        <div class="form mx-4 mt-10 before" autocomplete="off">
          <p class="text-white text-lg tracking-widest text-center">
            本次活動已兌換 {{ this.now }} 杯
          </p>
          <p class="text-white text-lg tracking-widest text-center">
            請點選以下按鈕掃描用戶票券
          </p>
          <div class="flex justify-center mt-10 pb-10" >
            <div class="hearts flex justify-end items-center w-1/4 px-5">
              <img class="w-1/3" src="@/assets/heart.png" />
            </div>
            <button 
              class="mx-auto bg-white text-buyBackground border-black border-2 shadow-inputActive rounded-lg text-lg px-5 py-2" 
              @click="scanUser"
            >
              立即掃描
            </button>
            <div class="hearts flex justify-start items-center w-1/4 px-5">
              <img class="w-1/3" src="@/assets/heart.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="footer fixed bottom-0 w-screen z-10 bg-black flex justify-center items-center py-3" ref="footer">
      <img
      class="ml-3 w-2/3 px-3"
      src="@/assets/drunk_notice.png"
      />
    </div>
    <div class="fake-footer opacity-0 fixed bottom-0 w-screen z-10 bg-black flex justify-center items-center py-3">
      <img
      class="ml-3 w-2/3 px-3"
      src="@/assets/drunk_notice.png"
      />
    </div>
  </div>
</template>

<script>
import liff from '@line/liff';
import axios from 'axios';

export default {
  name: 'App',
  data(){
    return {
      headerHeight: 0,
      footerHeight: 0,
      storeName: '',
      now: 0,
      noticePopup: false,
      msg: ''
    }
  },
  computed: {
    fullHeight: function(){
      let windowHeight = window.innerHeight;
      return {
        'min-height': `${windowHeight - this.headerHeight - this.footerHeight}px`,
      }
    }
  },
  methods: {
    closeWindow(){
      liff.closeWindow()
    },
    fetchStore(){
      axios
        .get(`https://api.hwstud.io/v2/getStore.php?userId=${liff.getContext().userId}`)
        .then((result) => {
          let response = result.data;
          if (response.status == 200) {
            this.storeName = response.name;
            this.now = response.now;
          }else{
            alert("此帳號不屬於任何店家");
            liff.closeWindow();
          }
        })
    },
    scanUser(){
      liff.scanCodeV2()
        .then(result => {
          const ticketNumber = result.value;
          axios
            .get(`https://api.hwstud.io/v2/getData.php?ticket=${ticketNumber}&userId=${liff.getContext().userId}`)
            .then(result => {
              let response = result.data;
              switch (response.status) {
                case 0:
                  this.msg = `${response.name} 兌換成功！`
                  this.noticePopup = true;
                  break;
                case -1:
                  this.msg = (`失敗！此票券尚未付款！`);
                  this.noticePopup = true;
                  break;
                case -2:
                  this.msg = (`失敗！此票券尚未完成心理測驗！`);
                  this.noticePopup = true;
                  break;
                case -3:
                  this.msg = (`失敗！此票券兌換此數已達上限！`);
                  this.noticePopup = true;
                  break;
                case -4:
                  this.msg = (`失敗！此票券曾經於貴店兌換！`);
                  this.noticePopup = true;
                  break;
                case -5:
                  this.msg = (`失敗！店家狀態異常，請重新開啟掃描！`);
                  this.noticePopup = true;
                  break
                default:
                  this.msg = ("失敗！系統異常，請重新掃描！")
                  this.noticePopup = true;
                  break;
              }
              this.fetchStore();
            })
        })
        .catch(() => {
          alert("開啟 QR Scanner 失敗！")
        })
    }
  },
  created(){
    window.onload = function(){
      document.addEventListener('touchstart', function(e) {
        if(e.touches.length > 1){
          e.preventDefault();
        }
      })
      document.addEventListener('gesturestart', function(e) {
        e.preventDefault();
      })
    }
  },
  mounted(){
    this.headerHeight = this.$refs.header.clientHeight;
    this.footerHeight = this.$refs.footer.clientHeight;

    liff
      .init({
        liffId: '1656804955-0Ox138zo', // Use own liffId
      })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login();
        }else{
          // 已登入，確認商家身份
          this.fetchStore();
        }
      })

    
  }
}
</script>

<style>

</style>
